// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Settings02 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m9.9745 20.6856-.5844-1.3145a2.4259 2.4259 0 0 0-1.0045-1.1167 2.434 2.434 0 0 0-1.47-.3122l-1.43.1522a2.2142 2.2142 0 0 1-2.4426-2.3171 2.2111 2.2111 0 0 1 .4204-1.1851l.8467-1.1633A2.4288 2.4288 0 0 0 4.7745 12a2.429 2.429 0 0 0-.4644-1.4289l-.8467-1.1633A2.2112 2.2112 0 0 1 3.3368 7a2.2122 2.2122 0 0 1 2.1488-1.0944l1.4345.1522a2.4334 2.4334 0 0 0 1.47-.3122 2.4256 2.4256 0 0 0 1-1.1167l.5844-1.3145A2.2121 2.2121 0 0 1 11.9968 2a2.2124 2.2124 0 0 1 2.0222 1.3144l.5889 1.3145a2.425 2.425 0 0 0 1 1.1167 2.4334 2.4334 0 0 0 1.47.3122l1.43-.1522A2.2125 2.2125 0 0 1 20.6568 7a2.2116 2.2116 0 0 1-.1267 2.4078l-.8467 1.1633A2.4293 2.4293 0 0 0 19.219 12a2.4284 2.4284 0 0 0 .46 1.4344l.8467 1.1634c.2518.346.3979.7577.4204 1.1851a2.2119 2.2119 0 0 1-.2938 1.2227A2.2122 2.2122 0 0 1 18.5034 18.1l-1.43-.1522a2.4327 2.4327 0 0 0-1.47.3122 2.426 2.426 0 0 0-1 1.1111l-.5844 1.3145A2.2123 2.2123 0 0 1 11.9968 22a2.212 2.212 0 0 1-2.0223-1.3144ZM15.101 12c0 1.6569-1.3431 3-3 3-1.6568 0-3-1.3431-3-3s1.3432-3 3-3c1.6569 0 3 1.3431 3 3Z"
      clipRule="evenodd"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 15c1.6569 0 3-1.3431 3-3s-1.3431-3-3-3-3 1.3431-3 3 1.3431 3 3 3Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m9.3951 19.3711.5845 1.3145A2.2122 2.2122 0 0 0 12.0017 22a2.2123 2.2123 0 0 0 2.0222-1.3144l.5844-1.3145a2.426 2.426 0 0 1 1-1.1111 2.4327 2.4327 0 0 1 1.47-.3122l1.43.1522a2.2121 2.2121 0 0 0 2.1489-1.0944 2.2119 2.2119 0 0 0 .2938-1.2227 2.2118 2.2118 0 0 0-.4204-1.1851l-.8467-1.1634A2.4284 2.4284 0 0 1 19.224 12a2.4293 2.4293 0 0 1 .4644-1.4289l.8467-1.1633A2.211 2.211 0 0 0 20.6618 7a2.2123 2.2123 0 0 0-2.1489-1.0944l-1.43.1522a2.4334 2.4334 0 0 1-1.47-.3122 2.4256 2.4256 0 0 1-1-1.1167l-.5889-1.3145A2.2122 2.2122 0 0 0 12.0018 2a2.2121 2.2121 0 0 0-2.0222 1.3144L9.395 4.629a2.4255 2.4255 0 0 1-1 1.1167 2.4334 2.4334 0 0 1-1.47.3122l-1.4344-.1522A2.2122 2.2122 0 0 0 3.3417 7a2.211 2.211 0 0 0 .1267 2.4078l.8467 1.1633c.302.4153.4645.9155.4645 1.4289a2.4289 2.4289 0 0 1-.4645 1.4289l-.8467 1.1633A2.211 2.211 0 0 0 3.3418 17a2.214 2.214 0 0 0 2.1489 1.0944l1.43-.1522a2.434 2.434 0 0 1 1.47.3122 2.4258 2.4258 0 0 1 1.0044 1.1167Z"
    />
  </svg>
);
export default Settings02;
