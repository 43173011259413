import React from 'react';
import styled from '@emotion/styled';
import Z from '@commandbar/internal/client/Z';

interface IRecorderFocusMaskContainer {
  top: number;
  left: number;
  display: string;
}

interface IRecorderFocusMaskArea {
  width: number;
  height: number;
}

const Container = styled.div<IRecorderFocusMaskContainer>`
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  display: ${(props) => props.display};
  position: fixed;
  z-index: ${Z.Z_INDEX_MAX.toString()};
  pointer-events: none;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MaskArea = styled.div<IRecorderFocusMaskArea>`
  outline: 2px solid #4497f7;
  background: rgba(68, 151, 247, 0.1);
  transition: all 100ms ease-in-out;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;

const LABEL_HEIGHT = 30;
const Label = styled.div<{ positionStyles: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${LABEL_HEIGHT}px;
  width: auto;
  overflow: auto;
  padding: 0 8px;
  border-radius: 4px;
  background-color: #4497f7;
  color: #fff;
  font-size: 12px;
  font-weight: 500;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  ${(props) => props.positionStyles}
`;

export type TRecorderFocusMask = IRecorderFocusMaskContainer & IRecorderFocusMaskArea & { selector?: string };

const truncateSelector = (selector?: string): string | undefined => {
  if (!selector) {
    return;
  }
  const MAX_LENGTH = 20;
  if (selector.length <= MAX_LENGTH) {
    return selector;
  }
  return `${selector.substring(0, MAX_LENGTH)}...]`;
};

const getLabelPositionStyles = (top: number, height: number, left: number, width: number): string => {
  const VERTICAL_THRESHHOLD = 100;
  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  const bottomBoundOfVisableScreen = scrollTop + window.innerHeight;
  const bottomBoundOfMask = top + height;

  /**
   *  Position underneath mask
   */
  if (bottomBoundOfMask + VERTICAL_THRESHHOLD + scrollTop < bottomBoundOfVisableScreen) {
    return 'top: calc(100% + 8px);';
  }

  /**
   * Position above mask
   */
  if (top >= VERTICAL_THRESHHOLD) {
    return `top: calc(-${LABEL_HEIGHT}px - 8px);`;
  }

  /**
   * Position relative to bottom of screen
   */
  if (bottomBoundOfMask > window.innerHeight) {
    return `
      position: fixed;
      bottom: ${LABEL_HEIGHT + 8}px;
      left: ${left + width / 2}px;
      transform: translateX(-50%);
    `;
  }
  /**
   * Position relative to bottom of mask
   */
  return `
    bottom: ${LABEL_HEIGHT + 8}px;
  `;
};
export const RecorderFocusMask = ({ selector, top, left, display, width, height }: TRecorderFocusMask) => {
  const truncatedSelector = truncateSelector(selector);
  const labelPosition = getLabelPositionStyles(top, height, left, width);
  return (
    <Container top={top} left={left} display={display}>
      <MaskArea width={width} height={height} />
      {truncatedSelector && <Label positionStyles={labelPosition}>{truncatedSelector}</Label>}
    </Container>
  );
};
