// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Tool02 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="m19.259 2.741-2.6276 2.6276c-.3961.396-.5941.594-.6683.8224a1.0009 1.0009 0 0 0 0 .618c.0742.2283.2722.4264.6683.8224l.2372.2372c.396.396.594.594.8224.6682a.9999.9999 0 0 0 .618 0c.2283-.0741.4263-.2721.8224-.6682l2.4579-2.4579A5.4844 5.4844 0 0 1 22 7.5c0 3.0376-2.4625 5.5-5.5 5.5a5.5259 5.5259 0 0 1-1.0703-.1041c-.4861-.0958-.7292-.1438-.8765-.1291-.1567.0156-.2339.0391-.3727.1134-.1306.0699-.2615.2008-.5235.4628L6.5 20.5c-.8285.8284-2.1716.8284-3 0-.8284-.8284-.8284-2.1716 0-3l7.157-7.157c.262-.262.3929-.393.4628-.5235.0743-.1388.0978-.216.1134-.3727.0147-.1473-.0333-.3904-.1292-.8766A5.5329 5.5329 0 0 1 11 7.5C11 4.4624 13.4624 2 16.5 2c1.0055 0 1.948.2698 2.759.741Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m6 6 4.5 4.5M6 6H3L2 3l1-1 3 1v3Zm13.259-3.259-2.6276 2.6276c-.396.396-.5941.594-.6682.8224a.9994.9994 0 0 0 0 .618c.0741.2283.2722.4264.6682.8224l.2372.2372c.396.396.5941.594.8224.6682a.9999.9999 0 0 0 .618 0c.2283-.0741.4264-.2721.8224-.6682l2.4579-2.4579A5.4844 5.4844 0 0 1 22 7.5c0 3.0376-2.4624 5.5-5.5 5.5a5.524 5.524 0 0 1-1.0702-.1041c-.4862-.0958-.7293-.1438-.8766-.1291-.1567.0156-.2339.0391-.3727.1134-.1306.0699-.2615.2008-.5235.4628L6.5 20.5c-.8284.8284-2.1716.8284-3 0-.8284-.8284-.8284-2.1716 0-3l7.157-7.157c.262-.262.3929-.393.4628-.5235.0743-.1388.0978-.216.1134-.3727.0147-.1473-.0333-.3904-.1291-.8766A5.525 5.525 0 0 1 11 7.5C11 4.4624 13.4624 2 16.5 2c1.0055 0 1.948.2698 2.759.741ZM12.0001 15 17.5 20.5c.8284.8284 2.1716.8284 3 0 .8284-.8284.8284-2.1716 0-3l-4.5247-4.5246a5.4747 5.4747 0 0 1-.9345-.171c-.3891-.1068-.8159-.0292-1.1011.256L12.0001 15Z"
    />
  </svg>
);
export default Tool02;
