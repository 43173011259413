// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const RollerBrush = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <g opacity={0.12}>
      <path
        fill="currentColor"
        d="M3 4.5c0-.4647 0-.697.0384-.8902a2 2 0 0 1 1.5714-1.5714C4.803 2 5.0353 2 5.5 2h11c.4647 0 .697 0 .8902.0384a2 2 0 0 1 1.5714 1.5714C19 3.803 19 4.0353 19 4.5s0 .697-.0384.8902a2 2 0 0 1-1.5714 1.5714C17.197 7 16.9647 7 16.5 7h-11c-.4647 0-.697 0-.8902-.0384a2 2 0 0 1-1.5714-1.5714C3 5.197 3 4.9647 3 4.5Zm7 12.1c0-.5601 0-.8401.109-1.054a.9998.9998 0 0 1 .437-.437C10.7599 15 11.0399 15 11.6 15h.8c.5601 0 .8401 0 1.054.109a.9998.9998 0 0 1 .437.437c.109.2139.109.4939.109 1.054v3.8c0 .5601 0 .8401-.109 1.054a.9998.9998 0 0 1-.437.437C13.2401 22 12.9601 22 12.4 22h-.8c-.5601 0-.8401 0-1.054-.109a.9998.9998 0 0 1-.437-.437C10 21.2401 10 20.9601 10 20.4v-3.8Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M3 4.5c0-.4647 0-.697.0384-.8902a2 2 0 0 1 1.5714-1.5714C4.803 2 5.0353 2 5.5 2h11c.4647 0 .697 0 .8902.0384a2 2 0 0 1 1.5714 1.5714C19 3.803 19 4.0353 19 4.5s0 .697-.0384.8902a2 2 0 0 1-1.5714 1.5714C17.197 7 16.9647 7 16.5 7h-11c-.4647 0-.697 0-.8902-.0384a2 2 0 0 1-1.5714-1.5714C3 5.197 3 4.9647 3 4.5Zm7 12.1c0-.5601 0-.8401.109-1.054a.9998.9998 0 0 1 .437-.437C10.7599 15 11.0399 15 11.6 15h.8c.5601 0 .8401 0 1.054.109a.9998.9998 0 0 1 .437.437c.109.2139.109.4939.109 1.054v3.8c0 .5601 0 .8401-.109 1.054a.9998.9998 0 0 1-.437.437C13.2401 22 12.9601 22 12.4 22h-.8c-.5601 0-.8401 0-1.054-.109a.9998.9998 0 0 1-.437-.437C10 21.2401 10 20.9601 10 20.4v-3.8Z"
      />
    </g>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 4.5c0 .4647 0 .697-.0384.8902a2 2 0 0 1-1.5714 1.5714C17.197 7 16.9647 7 16.5 7h-11c-.4647 0-.697 0-.8902-.0384a2 2 0 0 1-1.5714-1.5714C3 5.197 3 4.9647 3 4.5s0-.697.0384-.8902a2 2 0 0 1 1.5714-1.5714C4.803 2 5.0353 2 5.5 2h11c.4647 0 .697 0 .8902.0384a2 2 0 0 1 1.5714 1.5714C19 3.803 19 4.0353 19 4.5Zm0 0c.9319 0 1.3978 0 1.7654.1522.49.203.8794.5924 1.0824 1.0824C22 6.1022 22 6.5681 22 7.5v.3c0 1.1201 0 1.6802-.218 2.108a1.9996 1.9996 0 0 1-.874.874C20.4802 11 19.9201 11 18.8 11h-3.6c-1.1201 0-1.6802 0-2.108.218a1.9996 1.9996 0 0 0-.874.874C12 12.5198 12 13.0799 12 14.2v.8m-.4 7h.8c.5601 0 .8401 0 1.054-.109a.9998.9998 0 0 0 .437-.437C14 21.2401 14 20.9601 14 20.4v-3.8c0-.5601 0-.8401-.109-1.054a.9998.9998 0 0 0-.437-.437C13.2401 15 12.9601 15 12.4 15h-.8c-.5601 0-.8401 0-1.054.109a.9998.9998 0 0 0-.437.437C10 15.7599 10 16.0399 10 16.6v3.8c0 .5601 0 .8401.109 1.054a.9998.9998 0 0 0 .437.437c.2139.109.4939.109 1.054.109Z"
    />
  </svg>
);
export default RollerBrush;
