import React from 'react';

import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { EditorNavigation } from './components/EditorNavigation/EditorNavigation';
import { IOrganizationType } from '@commandbar/internal/middleware/types';

interface IProps {
  isEditorOpen: boolean;
  toggleEditor: () => void;
  activeRoute: string;
  setActiveRoute: (route: string) => void;
  isEditorLoggedIn: boolean;
  organization: IOrganizationType | null;
  releaseBadgeStatus: boolean;
}

const MIN_Y = 50;

// NOTE: this is needed because the rc-drawer "Drawer" component passes a "ref" to the handle, and React functional components don't support refs
class EditorHandle extends React.Component<IProps> {
  render() {
    // eslint-disable-next-line react/jsx-pascal-case
    return <_EditorHandle {...this.props} />;
  }
}

const _EditorHandle = (props: IProps) => {
  const isMouseDown = React.useRef(false);
  const isMovingHandle = React.useRef(false);
  const yPositionAtMouseDown = React.useRef(null);

  const [handleY, setHandleY] = React.useState(() => parseInt(LocalStorage.get('handleY', '55') as string));

  // Set a minimum mousemove delta to process a move
  // This avoids annoying situations where a user clicks a few pixels away and
  //    the click is processed as a drag
  const MIN_DELTA = 100;

  const onMouseDown = (e: any) => {
    isMouseDown.current = true;
    yPositionAtMouseDown.current = e.clientY;
  };

  const onMouseMove = (e: any) => {
    if (!isMouseDown.current) {
      return;
    }

    // Only process a move if it meets the minimum threshold
    const amountMovedIsSignificant = Math.abs((yPositionAtMouseDown.current || handleY) - e.clientY) > MIN_DELTA;
    const newPosition = e.clientY;
    const newPositionIsInView = newPosition < window.innerHeight && window.innerHeight - newPosition > MIN_DELTA;
    if (amountMovedIsSignificant && newPositionIsInView) {
      isMovingHandle.current = true;
      setHandleY(newPosition);
    }
  };

  const onMouseUp = () => {
    if (isMouseDown) {
      LocalStorage.set('handleY', handleY);
      // A little hack. Mouseup is called BEFORE click
      // In order to prevent a click event at the end of drag, we need to add a timeout
      //    before setting the move event to being complete
      setTimeout(() => (isMovingHandle.current = false), 200);
      isMouseDown.current = false;
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, []);

  const top = Math.max(handleY, MIN_Y);
  const shiftHandleX = props.isEditorOpen && props.isEditorLoggedIn ? 'translate(-65px)' : 'translate(65px)';

  return (
    <div
      onClick={() => {
        if (!isMovingHandle.current) {
          // If the handle is being moved, don't process a click
          // props.dispatch.setRecorderCommand(undefined);
          props.toggleEditor();
        }
      }}
      onMouseDown={onMouseDown}
      className="commandbar-editor-drawer-handle"
      style={{
        backgroundColor: 'rgb(242,242,242)',
        color: 'rgba(0,0,0,0.85)',
        borderRadius: '0px 4px 4px 0px',
        top,
        transform: shiftHandleX,
        cursor: 'grab',
        boxShadow: 'none',
        userSelect: 'none',
        WebkitUserSelect: 'none',
        display: 'flex',
        alignItems: 'start',
      }}
    >
      <EditorNavigation
        isEditorLoggedIn={props.isEditorLoggedIn}
        isEditorOpen={props.isEditorOpen}
        activeRoute={props.activeRoute}
        onRouteChange={props.setActiveRoute}
        organization={props.organization}
        releaseBadgeStatus={props.releaseBadgeStatus}
      />
    </div>
  );
};

export default EditorHandle;
