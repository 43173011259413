import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { SentryErrorBoundary } from '@commandbar/internal/util/sentry';

export const container = document.getElementById('proxy');

ReactDOM.render(
  <SentryErrorBoundary>
    <App />
  </SentryErrorBoundary>,
  container,
);
