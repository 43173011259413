/******************************************************************/
/* Imports
/******************************************************************/

export const getQueryStringType = (x: string): 'css' | 'xpath' => {
  if (x.substring(0, 2) === '//') {
    return 'xpath';
  } else {
    return 'css';
  }
};

/******************************************************************/

export const loadFromSelector = (x: string, logError?: (x: string) => void) => {
  if (typeof x !== 'string' || x.length === 0) return null;
  try {
    const elem = document.querySelector(x);
    return elem;
  } catch (e) {
    if (logError) {
      logError(x);
    }
    return null;
  }
};

/******************************************************************/

// https://stackoverflow.com/a/14284815/1569490
export const loadFromXPath = (x: string, logError?: (x: string) => void) => {
  if (typeof x !== 'string' || x.length === 0) return null;
  try {
    const xPathRes = document.evaluate(x, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null);
    if (xPathRes.singleNodeValue) {
      return xPathRes.singleNodeValue as HTMLElement;
    } else {
      return null;
    }
  } catch (e) {
    if (logError) {
      logError(x);
    }
    return null;
  }
};

/******************************************************************/

export function getScrollParent(node: HTMLElement | null): HTMLElement | null {
  if (node == null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  } else {
    return getScrollParent(node.parentElement);
  }
}

/******************************************************************/

export function isHidden(el: HTMLElement) {
  return el.offsetParent === null;
}

/******************************************************************/

const isElementHidden = (rect: DOMRect, parentRect: DOMRect, parentStyle: CSSStyleDeclaration) => {
  const isDisplayNone = parentStyle.display === 'none';
  const isVisibilityHidden = parentStyle.visibility === 'hidden';

  const isOverflowHidden = parentStyle.overflow === 'hidden';
  const isOverflowXHidden = parentStyle.overflowX === 'hidden';
  const isOverflowYHidden = parentStyle.overflowY === 'hidden';

  const isHorizontallyHidden =
    (isOverflowHidden || isOverflowXHidden) && (rect.right < parentRect.left || rect.left > parentRect.right);
  const isVerticallyHidden =
    (isOverflowHidden || isOverflowYHidden) && (rect.bottom < parentRect.top || rect.top > parentRect.bottom);

  return isDisplayNone || isVisibilityHidden || isHorizontallyHidden || isVerticallyHidden;
};

function isVisibleOnPage(element: HTMLElement) {
  // Check if the element has display: none or visibility: hidden
  const style = window.getComputedStyle(element);
  if (style.display === 'none' || style.visibility === 'hidden') {
    return false;
  }

  // Get the element's dimensions and position in the viewport
  const rect = element.getBoundingClientRect();

  // Check if the element or any of its parents have display: none, visibility: hidden, or overflow: hidden
  let parent = element.parentNode as HTMLElement | null;
  while (parent && parent !== document.body) {
    const parentStyle = window.getComputedStyle(parent);
    const parentRect = parent.getBoundingClientRect();

    if (isElementHidden(rect, parentRect, parentStyle)) {
      return false;
    }

    parent = parent.parentNode as HTMLElement | null;
  }

  // Check if the element is visible in the current viewport or can be scrolled to
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;
  const vertInView = rect.top <= windowHeight && rect.top + rect.height >= 0;
  const horInView = rect.left <= windowWidth && rect.left + rect.width >= 0;

  return vertInView && horInView;
}

export const checkSelectorAndVisibility = (selector: string): { elementFound: boolean; elementVisible: boolean } => {
  const type = getQueryStringType(selector);
  let element: HTMLElement | null;
  if (type === 'xpath') {
    element = loadFromXPath(selector) as HTMLElement | null;
  } else {
    element = loadFromSelector(selector) as HTMLElement | null;
  }

  if (element) {
    return { elementFound: true, elementVisible: isVisibleOnPage(element) };
  } else {
    return { elementFound: false, elementVisible: false };
  }
};

export const checkSelector = (selector: string): boolean => {
  const type = getQueryStringType(selector);
  let element: HTMLElement | null;
  if (type === 'xpath') {
    element = loadFromXPath(selector) as HTMLElement | null;
  } else {
    element = loadFromSelector(selector) as HTMLElement | null;
  }

  return !!element;
};

/******************************************************************/

export function clickElement(selector: string, logError?: (x: string) => void): boolean {
  const type = getQueryStringType(selector);

  let element;
  if (type === 'xpath') {
    // grab the element
    element = loadFromXPath(selector) as HTMLElement | SVGElement | null;
  } else if (type === 'css') {
    element = loadFromSelector(selector) as HTMLElement | SVGElement | null;
  }

  if (!element) {
    if (logError) {
      logError(selector);
    }
    return false;
  }

  if (element instanceof HTMLElement) {
    element.click();
  } else if (element instanceof SVGElement) {
    element.dispatchEvent(new MouseEvent('click', { bubbles: true }));
  }

  return true;
}

export const getElement = (selector: string): HTMLElement | undefined => {
  const type = getQueryStringType(selector);

  let element;
  if (type === 'xpath') {
    // grab the element
    element = loadFromXPath(selector) as HTMLElement | undefined;
  } else if (type === 'css') {
    element = loadFromSelector(selector) as HTMLElement | undefined;
  }

  return element;
};

/******************************************************************/

export function clickElementsWithPause(index: number, selectors: string[], logError?: (x: string) => void) {
  if (index >= selectors.length) {
    return;
  }

  const type = getQueryStringType(selectors[index]);

  let element;
  if (type === 'xpath') {
    // grab the element
    element = loadFromXPath(selectors[index]) as HTMLElement | SVGElement | null;
  } else if (type === 'css') {
    element = loadFromSelector(selectors[index]) as HTMLElement | SVGElement | null;
  }

  if (!element) {
    if (logError) {
      logError(selectors[index]);
    }
    return;
  }

  if (element instanceof HTMLElement) {
    element.click();
  } else if (element instanceof SVGElement) {
    element.dispatchEvent(new MouseEvent('click', { bubbles: true }));
  }
  setTimeout(() => {
    clickElementsWithPause(index + 1, selectors);
  }, 150);
}

const DOM = {
  checkSelector,
  clickElement,
  clickElementsWithPause,
};

export default DOM;
