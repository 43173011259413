import React, { useRef, useState } from 'react';
import { css, cx } from '../../util/emotion';

import { NavItem } from './NavItem';
import { getColor, rightInsetShadow, standardBoxShadow } from './styles';
import { useGlobalAppContext } from '../../AppContext';

import { ArrowLeft, Download04 } from '@commandbar/design-system/icons/react';

import * as Organization from '@commandbar/internal/middleware/organization';
import { main_items, secondary_items, settings_items } from './tabs';
import { ChevronLeftFilled, ChevronRightFilled } from '@commandbar/design-system/icons/react';
import { IOrganizationType } from '@commandbar/internal/middleware/types';

const styles = {
  headerContainer: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 120px;
    font-size: 14px;
    font-weight: 600;
    height: 38px;
    color: ${getColor('grey')};
    cursor: pointer;
  `,

  itemContainer: css`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    height: calc(100% - 50px);
    justify-content: space-between;
  `,

  installCBLinkContainer: css`
    margin: 8px;
  `,

  installCBLink: css`
    background: linear-gradient(267.7deg, #7a4cf0 5.95%, #a064dd 14.19%, #6340ed 48.21%, #582ff1 95.66%);
    border: 1px solid #421fb5;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16), inset 0px 2px 0px rgba(255, 255, 255, 0.09);
    color: #ffffff;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: 100%;
    height: 30px;

    &:active,
    &:hover,
    &:focus {
      color: #ffffff;
    }
  `,

  installCBIconOnlyStyle: css`
    justify-content: center;
    align-items: center;
    display: flex;
    width: 30px;
    padding: 0;
    margin: 8px 4px;
  `,

  mainContainer: css`
    border: 0.5px solid ${getColor('mainContainerBorder')};
    border-radius: 8px 0px 0px 8px;
    box-shadow: ${standardBoxShadow}, ${rightInsetShadow};
    font-family: Avenir Next, proxima-nova, sans-serif;
    display: flex;
    flex-direction: column;
    background: ${getColor('white')};
    position: relative;
    height: auto;
    max-height: 36px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    width: 170px;
  `,

  mainContainerPadding: css`
    padding: 0;
  `,

  mainContainerStandaloneEditorPadding: css`
    height: 100%;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    font-size: 14px;
  `,

  mainContainerExpanded: css`
    max-height: 100vh;
  `,

  mainContainerStandaloneEditor: css`
    height: 100vh;
    width: 160px;
    background: ${getColor('white')};
    box-shadow: none;
  `,

  separator: css`
    margin-bottom: 8px;
    border-top: 1px solid #e5e4e7;
  `,

  separatorStandaloneEditor: css`
    margin-top: 2px;
    margin-bottom: 8px;
  `,

  editorTitle: css`
    margin: 0px 34px 0px 10px;
    font-weight: 600;
    font-size: 14px;
    color: #7a7a85;
    display: flex;
    gap: 8px;
    align-items: center;
  `,

  editorTitleClosed: css`
    margin: 0 8px;
  `,

  dashboardLink: css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 8px 8px;
    gap: 8px;
    justify-content: start;
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    background: none;
    border: none;
    border-radius: 4px;
    color: #51515c;
    cursor: pointer;
    line-height: 14px;
    width: 143px;
    padding: 8px;
    height: 32px;
    margin: 4px 8px;
    &:hover {
      background: ${getColor('mainPurpleLight')};
      color: ${getColor('mainPurple')};
    }
  `,
  divider: css`
    width: 186px;
    height: 1px;
    background: #dfdfe2;
    border-radius: 1px;
  `,
};

type Props = {
  isEditorOpen: boolean;
  activeRoute: string;
  onRouteChange: (route: string) => void;
  isEditorLoggedIn: boolean;
  organization: IOrganizationType | null;
  releaseBadgeStatus: boolean;
};

export const EditorNavigation = (props: Props) => {
  const [hasInstalled, setHasInstalled] = React.useState(true);
  const { isEditorOpen, activeRoute, isEditorLoggedIn, organization, releaseBadgeStatus, onRouteChange } = props;
  const globalAppContext = useGlobalAppContext();
  const isEditorCollapsed = isEditorLoggedIn && !isEditorOpen;

  const [hovered, setHover] = useState(false);

  const timeout = useRef<ReturnType<typeof setTimeout>>();

  React.useEffect(() => {
    if (!!props.organization?.id) {
      Organization.read(`${props.organization.id}`).then((organization) => {
        setHasInstalled(!!organization?.installed_at);
      });
    }
  }, [props.organization?.id]);

  const handleItemsClick = (e: React.MouseEvent) => {
    if (isEditorOpen) {
      e.stopPropagation();
    }
  };

  const getMainContainerClassName = () => {
    const baseStyles = [styles.mainContainer];

    if (!isEditorLoggedIn) {
      return cx(baseStyles);
    }

    if (isEditorOpen || hovered) {
      if (globalAppContext?.isStandaloneEditor) {
        return cx(baseStyles.concat(styles.mainContainerExpanded, styles.mainContainerStandaloneEditor));
      }

      return cx(baseStyles.concat(styles.mainContainerExpanded));
    }

    return cx(baseStyles);
  };

  const handleMouseLeave = () => {
    timeout.current = setTimeout(() => {
      setHover(false);
    }, 200);
  };

  const handleMouseOver = () => {
    timeout.current && clearTimeout(timeout.current);
    setHover(true);
  };

  const callIfOpen = (fn: Function, ...args: any[]) => {
    if (!isEditorLoggedIn) return;
    return fn(...args);
  };

  return (
    <div
      onMouseLeave={callIfOpen.bind({}, handleMouseLeave)}
      onMouseOver={callIfOpen.bind({}, handleMouseOver)}
      id="editor_nav"
      data-custom-type="editor_nav"
    >
      <div className={getMainContainerClassName()}>
        <div
          className={
            globalAppContext?.isStandaloneEditor
              ? styles.mainContainerStandaloneEditorPadding
              : styles.mainContainerPadding
          }
        >
          {globalAppContext?.isStandaloneEditor ? (
            <a className={styles.dashboardLink} href="/">
              <ArrowLeft height={14} />
              Back
            </a>
          ) : (
            <>
              <div data-custom-type="menu_header" className={styles.headerContainer}>
                <div className={styles.editorTitle}>
                  {props.isEditorOpen && props.isEditorLoggedIn ? (
                    <>
                      Editor <ChevronRightFilled height={10} />
                    </>
                  ) : (
                    <div className={styles.editorTitleClosed}>
                      <ChevronLeftFilled height={10} />
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          <div data-custom-type="nav_items_container" onClick={handleItemsClick} className={styles.itemContainer}>
            <div>
              {/* {!hasInstalled && !globalAppContext?.isStandaloneEditor && ( */}
              {!hasInstalled && (
                <>
                  <div className={styles.divider}></div>
                  <div
                    className={cx([styles.installCBLinkContainer, isEditorCollapsed && styles.installCBIconOnlyStyle])}
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://app.commandbar.com/installation-guide"
                      className={styles.installCBLink}
                    >
                      <Download04 height={14} />
                      {!isEditorCollapsed && <>Install to Publish</>}
                    </a>
                  </div>
                </>
              )}
              <div className={styles.divider}></div>
              {main_items.map((item) => (
                <NavItem
                  key={`nav-item-${item.title}`}
                  onClick={() => item.route && onRouteChange(item.route)}
                  active={!!item.route && activeRoute.startsWith(item.route)}
                  item={item}
                  badge={false}
                  isEditorCollapsed={isEditorCollapsed}
                />
              ))}
              <div className={styles.divider}></div>
              {secondary_items.map((item) => (
                <NavItem
                  key={`nav-item-${item.title}`}
                  onClick={() => item.route && onRouteChange(item.route)}
                  active={!!item.route && activeRoute.includes(item.route)}
                  item={item}
                  badge={false}
                  isEditorCollapsed={isEditorCollapsed}
                />
              ))}
            </div>
            <div>
              <div className={styles.divider}></div>
              {settings_items.map((item) => {
                let badge = false;
                if (item.title === 'Releases') {
                  if (!organization?.releases_available) return null;

                  badge = releaseBadgeStatus;
                }
                return (
                  <NavItem
                    key={`nav-item-${item.title}`}
                    onClick={() => item.route && onRouteChange(item.route)}
                    active={!!item.route && activeRoute.startsWith(item.route)}
                    item={item}
                    badge={badge}
                    isEditorCollapsed={isEditorCollapsed}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
