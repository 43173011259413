// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Download04 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M12 22c5.5228 0 10-4.4772 10-10 0-5.5229-4.4772-10-10-10C6.4771 2 2 6.4771 2 12c0 5.5228 4.4771 10 10 10Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m8 12 4 4m0 0 4-4m-4 4V6.8c0-1.3907 0-2.086-.5505-2.8646-.3658-.5173-1.4189-1.1557-2.0467-1.2408-.945-.128-1.3037.0592-2.0214.4335C4.1834 4.7964 2 8.1432 2 12c0 5.5228 4.4771 10 10 10 5.5228 0 10-4.4772 10-10 0-3.7014-2.011-6.9331-5-8.6622"
    />
  </svg>
);
export default Download04;
