import { strict as assert } from 'assert';

interface Test {
  description: string;
  testFunction: Function;
}

interface TestSuite {
  title: string;
  tests: Test[];
}

const BrowserEnvironmentTestSuite: TestSuite = {
  title: 'Browser Environment',
  tests: [
    {
      description: 'Browser API checks',
      testFunction: async (): Promise<boolean> => {
        try {
          assert.strictEqual(typeof Promise, 'function');
          assert.strictEqual('resolve' in Promise, true);
          assert.strictEqual('reject' in Promise, true);
          assert.strictEqual(typeof Promise.resolve, 'function');
          assert.strictEqual(typeof Promise.reject, 'function');
          let result = false;
          await Promise.resolve(42).finally(() => (result = true));
          assert.strictEqual(result, true);
          return true;
        } catch (err) {
          console.log('CommandBar checks', err);
          return false;
        }
      },
    },
  ],
};

const ConfigurationTestSuite: TestSuite = {
  title: 'CommandBar Configuration',
  tests: [
    {
      description: '.boot() is called once',
      testFunction: (): boolean => {
        try {
          const logs = (window as any).CommandBarLogs || [];
          const isBootCalledOnce = logs.filter((log: any) => log.name === 'boot').length <= 1;
          assert.strictEqual(isBootCalledOnce, true);
          return true;
        } catch (err) {
          console.log('CommandBar checks', err);
          return false;
        }
      },
    },
  ],
};

const Checks: TestSuite[] = [BrowserEnvironmentTestSuite, ConfigurationTestSuite];

export const runChecks = async () => {
  const results = [];
  for (const { title, tests } of Checks) {
    for (const { description, testFunction } of tests) {
      const passed = await testFunction();
      results.push({
        check: `[${title}]: ${description}`,
        status: passed ? 'Passed' : 'Failed',
      });
    }
  }
  return results;
};
