import {
  BookOpen01,
  CheckSquareBroken,
  NotificationBox,
  Package,
  RollerBrush,
  Rows01,
  SearchSm,
  Settings02,
  Tool02,
  Users03,
} from '@commandbar/design-system/icons/react';

import * as editorRoutes from '@commandbar/internal/proxy-editor/editor_routes';
import { MenuItem } from './NavItem';

export const main_items: MenuItem[] = [
  {
    title: 'HelpHub',
    route: editorRoutes.HELPHUB_PARENT_ROUTE,
    Icon: BookOpen01,
  },
  {
    title: 'Bar',
    route: editorRoutes.COMMANDS_ROUTE,
    Icon: Rows01,
  },
  {
    title: 'Nudges',
    route: editorRoutes.NUDGE_ROUTE,
    Icon: NotificationBox,
  },
  {
    title: 'Questlists',
    route: editorRoutes.CHECKLIST_ROUTE,
    Icon: CheckSquareBroken,
  },
];

export const secondary_items: MenuItem[] = [
  {
    title: 'Audiences',
    route: editorRoutes.AUDIENCES_ROUTE,
    Icon: Users03,
  },
  {
    title: 'Records',
    route: editorRoutes.RECORDS_ROUTE,
    Icon: SearchSm,
  },
];

export const settings_items: MenuItem[] = [
  {
    title: 'Theme',
    route: editorRoutes.DESIGN_ROUTE,
    Icon: RollerBrush,
  },
  {
    title: 'Releases',
    route: editorRoutes.RELEASES_ROUTE,
    Icon: Package,
  },
  {
    title: 'Tools',
    route: editorRoutes.TOOLS_PARENT_ROUTE,
    Icon: Tool02,
  },
  {
    title: 'Settings',
    route: editorRoutes.SETTINGS_ROUTE,
    Icon: Settings02,
  },
];
