// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const ChevronRightFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 5 8" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.84812 7.35355C5.04339 7.15829 4.99458 6.64645 4.99458 6.64645L4.99457 4L4.99457 1.35355C4.99457 1.35355 5.04339 0.841709 4.84812 0.646447C4.65286 0.451185 4.33628 0.451185 4.14102 0.646447L1.14102 3.64645C0.945755 3.84171 0.945755 4.15829 1.14102 4.35355L4.14102 7.35355C4.33628 7.54882 4.65286 7.54882 4.84812 7.35355Z"
      fill="#7A7A85"
    />
  </svg>
);
export default ChevronRightFilled;
