import React from 'react';

import WindowStorage from '@commandbar/internal/util/WindowStorage';

export interface IFrameProps {
  id: string;
  src: string | undefined;
  height: number | string;
  width: number | string;
  allow: string | undefined;
  style: React.CSSProperties;
}

const Frame = (props: IFrameProps) => {
  const { id, ...otherProps } = props;
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (!!ref && !!ref.current) {
      // @ts-expect-error: Legacy typecheck from refactor
      if (ref.current?.nodeName !== 'IFRAME') {
        return;
      }

      // @ts-expect-error: Legacy typecheck from refactor
      if (!ref.current?.id) {
        return;
      }

      WindowStorage.frames = {
        ...(WindowStorage.frames ?? {}),
        // @ts-expect-error: Legacy typecheck from refactor
        [ref.current.id]: ref.current,
      };
    }
  }, [ref.current]);

  return <iframe title={`commandbar-${id}`} id={`commandbar-${id}`} {...otherProps} ref={ref} />;
};

export default Frame;
