// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Users03 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M9.5 12c2.4852 0 4.5-2.0147 4.5-4.5S11.9852 3 9.5 3C7.0147 3 5 5.0147 5 7.5S7.0147 12 9.5 12Zm0 3c-2.8307 0-5.3465 1.5446-6.9408 3.9383-.3493.5245-.524.7867-.5038 1.1216.0156.2608.1866.5801.395.7377C2.718 21 3.0861 21 3.8224 21h11.3552c.7362 0 1.1043 0 1.3719-.2024.2084-.1576.3794-.4769.395-.7377.0201-.3349-.1545-.5971-.5038-1.1216C14.8464 16.5446 12.3306 15 9.5 15Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18 15.8369c1.4559.7314 2.7041 1.9051 3.6152 3.3727.1804.2907.2706.436.3018.6372.0634.409-.2162.9117-.5971 1.0736C21.1325 21 20.9216 21 20.5 21M16 11.5322c1.4817-.7363 2.5-2.2653 2.5-4.0322 0-1.7669-1.0183-3.2959-2.5-4.0322M14 7.5c0 2.4853-2.0148 4.5-4.5 4.5C7.0147 12 5 9.9853 5 7.5S7.0147 3 9.5 3C11.9852 3 14 5.0147 14 7.5ZM2.5592 18.9383C4.1535 16.5446 6.6693 15 9.5 15c2.8306 0 5.3464 1.5446 6.9407 3.9383.3493.5245.5239.7867.5038 1.1216-.0156.2608-.1866.5801-.395.7377C16.2819 21 15.9138 21 15.1776 21H3.8223c-.7362 0-1.1043 0-1.3719-.2024-.2084-.1576-.3794-.4769-.395-.7377-.0201-.3349.1545-.5971.5038-1.1216Z"
    />
  </svg>
);
export default Users03;
